<template>
    <router-link class="link-wrapper" v-if="!showMore" :to="linkTo" :title="linkToTitle" :alt="linkToTitle">
        <div :class="['card', autoWidth ? 'card-block': '',noslider ? 'no-slider': '',typeClass, isHighlighted, address!=''?'has-address':'no-address']" @click="highlight">
            <div class="card-head">
                <div class="card-img">
                    <div v-if="icon!==null" :alt="subCategory" class="card-img__icon"><img :src="icon"></div>
                </div>
                <div class="card-content icon-card">
                    <div v-if="logo!==''" class="image">
                        <img :src="logo" alt="Logo">
                    </div>
                    <h3 class="title">{{ title }}</h3>
                </div>
            </div>
            <div class="card-content">

                <ul class="card-content__list">
                    <li v-if="nextAppointmentStartTime!==null && nextAppointmentToTime!==null">
                        <div class="icon"><span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">schedule</span></div>
                        <div class="info"><span>{{nextAppointmentDate}}, {{ nextAppointmentStartTime }} - {{nextAppointmentToTime}}</span></div>
                    </li>
                    <li v-if="address !== ''">
                        <div class="icon"><span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">place</span></div>
                        <div class="info do-not-translate" data-wg-notranslate="">
                            <span>{{institution}}</span>
                            <span class="text-muted" v-html="address"></span>
                        </div>
                    </li>

                    <li v-if="costs!==null && costs!==''">
                        <div class="icon"><span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">euro_symbol</span></div>
                        <div class="info" v-if="costs=='Kostenlos'"><span>Ücretsiz</span></div>
                        <div class="info" v-else=""><span>{{ costs }}</span></div>
                    </li>
                </ul>
                <bookmark-button :parentClass="'card-body-icon'" :content="content" v-if="content!==null"></bookmark-button>
                <card-share-button :link="linkTo"></card-share-button>
            </div>            
        </div>
    </router-link>
</template>

<script>
import { getNextAppointment, isOneOfTheAppointmentsNow, getWeekday, formatTime, getMonth, getNextDateForWday, getReadableDate } from '@/utils/appointment-helpers';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { getCategoryIcon, getSubCategory } from '@/utils/lupe-helpers';
import { getFieldValues, getFirstFieldValue } from '@/utils/helpers';
import { bus } from '@/main';


export default {
    name: 'AngebotCard',
    mixins: [screenSizeMixin],
    components: {
        CardShareButton: () => import('@/components/controls/CardShareButton.vue'),
        BookmarkButton: () => import('@/components/controls/BookmarkButton'),
    },
    props: {
        showMore: {
            type: Boolean,
        default: false
        },
        item: {
            type: Object,
            required: true
        },
        autoWidth: {
            type: Boolean,
        default: false,
        },
        noslider: {
            type: Boolean,
        default: false,
        },
        target: {
            type: String,
        default: "list",
        },
        openInNewTab: {
            type: Boolean,
        default: false,
        }
    },
    computed: {
        title() {
            var maxTextLength = 50;
            var title = this.item.title.length > maxTextLength ? this.item.title.substring(0, maxTextLength) + ' ...' : this.item.title;
            return title;
        },
        content() {
            if (this.item != null) {
                return this.item;
            }
            return null;
        },
        openNewTab() {
            if (this.openInNewTab) {
                return "_blank";
            }
            return "_self";
        },
        linkTo() {
            return "/angebote/" + this.item.id;
        },
        linkToTitle() {
            return "Mehr Informationen zu: " + this.item.title;
        },
        category() {
            var value = getFirstFieldValue(this.item, 'kielrefugeecompass');
            if (value === "Health") {
                return "Counselling";
            }
            return value != null ? value : '';
        },
        costs() {
            var value = getFirstFieldValue(this.item, 'kosten');
            if (value !== null) {
                value = value.length > 50 ? value.substring(0, 50) + ' ...' : value;
            }
            return value != null ? value : '';
        },
        barrierefrei() {
            var value = getFirstFieldValue(this.item, 'barrierefreiheit');
            return value != null ? value : '';
        },
        institution() {
            if (this.item !== null) {
                return this.item.institution_title;
            }
            return null;
        },
        subCategory() {
            return getSubCategory(this.item, this.category);
        },
        isHighlighted() {
            if (this.item.hasOwnProperty("highlight") && this.item.highlight == true) {
                return "highlighted";
            }
            return "not-highlighted"
        },
        categoryClass() {
            switch (this.category) {
            case 'Bildung':
            case 'Eğitim':
            case 'Education':
            case 'تعليم':
            case 'Освіта':
                return 'Bildung';
                break;
            case 'Freizeit':
            case 'Boş Zaman':
            case 'Leisure':
            case 'وقت الفراغ':
            case 'Вільний час':
                return 'Freizeit';
                break;
            case 'Ehrenamt & Engagement':
            case 'Gönüllülük ve Bağlılık':
            case 'Volunteering & Commitment':
            case 'التطوع والالتزام':
            case 'Волонтерство та відданість':
                return 'Ehrenamt & Engagement';
                break;
            case 'Beratung':
            case 'Tavsiye':
            case 'Counselling':
            case 'نصيحة':
            case 'Порада':
                return 'Beratung';
                break;
            case 'Sprache':
            case 'Dil':
            case 'Language':
            case 'لغة':
            case 'Мова':
                return 'Sprache';
                break;
            case 'Wohnen':
            case 'İkamet':
            case 'Housing':
            case 'مسكن':
            case 'Проживати':
                return 'Wohnen';
                break;
            default:
                return '';
            }
        },
        icon() {
            switch (this.categoryClass) {
            case 'Bildung':
                return '/assets/icons/kategorien/bildung.png';
                break;
            case 'Freizeit':
                return '/assets/icons/kategorien/freizeit.png';
                break;
            case 'Ehrenamt & Engagement':
                return '/assets/icons/kategorien/ehrenamt.png';
                break;
            case 'Beratung':
                return '/assets/icons/kategorien/beratung.png';
                break;
            case 'Sprache':
                return '/assets/icons/kategorien/sprache.png';
                break;
            case 'Wohnen':
                return '/assets/icons/kategorien/wohnen.png';
                break;
            default:
                return '/assets/RefugeeCompass_bg.png';
            }
        },
        typeClass() {
            if (this.categoryClass !== null && this.categoryClass !== '') {
                return this.categoryClass !== null ? this.categoryClass.replace(/\s/g, '').replace(/[^A-Za-z0-9\-_]/g, '') : '';
            } else {
                return 'no-cat';
            }
        },
        logo() {
            if (this.item !== null) {
                var logo = getFirstFieldValue(this.item, 'logo');
                if (logo !== null) {
                    return this.$backendUrl + logo;
                }
            }
            return "";
        },
        address() {
            var address = getFieldValues(this.item, 'adresse');
            if (address !== null) {
                var gna = "";
                if (address.hasOwnProperty('street') && address.street !== null) {
                    gna = gna + address.street + '<br>';
                }
                if (address.hasOwnProperty('zipcode') && address.zipcode !== null) {
                    gna = gna + address.zipcode + ', ';
                }
                if (address.hasOwnProperty('city') && address.city !== null) {
                    gna = gna + address.city;
                }

                if (gna !== "") {
                    gna = gna.length > 40 ? gna.substring(0, 40) + ' ...' : gna;
                }

                return gna;
            }
            return "";
        },
        appointments() {
            return getFirstFieldValue(this.item, 'appointments');
        },
        nextAppointment() {
            if (this.date != null) {
                return getNextAppointment(this.appointments, this.date);
            }
            return getNextAppointment(this.appointments);
        },
        nextAppointmentWday() {
            if (this.nextAppointment != null && this.nextAppointment.wday != null) {
                var day = getWeekday(this.nextAppointment.wday);
                return day.substring(0, 2);
            }
            return null;
        },
        nextAppointmentDate() {
            var date = null;
            if (this.nextAppointment != null && this.nextAppointment.date != null) {
                date = new Date(this.nextAppointment.date);
            } else if (this.nextAppointment != null && this.nextAppointment.wday !== null) {
                var bla = getNextDateForWday(this.nextAppointment.wday);
                date = new Date(bla);
            }

            if (date != null) {
                return (getReadableDate(date, "normal"));
            }

            return null;
        },
        nextAppointmentStartTime() {
            if (this.nextAppointment != null && this.nextAppointment.time != null && this.nextAppointment.time.time_from != null) {
                var time = this.nextAppointment.time.time_from;
                return formatTime(time);
            }
            return null;
        },
        nextAppointmentToTime() {
            if (this.nextAppointment != null && this.nextAppointment.time != null && this.nextAppointment.time.time_to != null) {
                var time = this.nextAppointment.time.time_to;
                return formatTime(time);
            }
            return null;
        },
        nextAppointmentMonthAndYear() {
            if (this.nextAppointment != null && this.nextAppointment.date != null) {
                var date = new Date(this.nextAppointment.date);
                var month = getMonth(date.getMonth() + 1);
                var year = date.getFullYear();
                return month.substring(0, 3) + '. ' + year;
            }
            return null;
        },
    },
    methods: {
        getFieldValues,
        getFirstFieldValue,
        showMerklisteModal() {
            bus.$emit('merkliste-modal', true)
        },
        highlight(event) {
            if (this.target == "map") {
                this.$emit('highlight', {sender: 'card', content: this.item});
            }
        }
    }
}
</script>

<style lang="scss" scoped="">
@import '@/scss/_variables.scss';

.card {
    display: flex;
    position: relative;
    width: 100%;
    border: none;
    margin-right: 15px;
    overflow: visible;
    box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 0px 40px;
    height: 380px;

    &-head {
        overflow: hidden;
    }

    &.has-address{
        cursor: pointer;
    }

    .card-img__icon{
        fill: $primary;
    }

    &.no-cat {
        .card-head {
            background-color: #E8F6FC;
        }
        &.highlighted {
            border: 3px solid #C7E4FF;
        }
        .card-img {
            border: 3px solid #C7E4FF;
        }
        .card-img__icon img {
            width: 60px;
            height: auto;
            max-width: 60px;
            max-height: 60px;
        }
    }

    &.Freizeit{
        .card-head {
            background-color: #C7E4FF;
        }
        &.highlighted{
            border: 3px solid #90C9FF;
        }

        .card-img{
            border: 3px solid #90C9FF;
        }

        .card-img__icon{
            fill: $primary;
        }
    }

    &.Wohnen{
        .card-head {
            background-color: #DED5F3;
        }
        &.highlighted{
            border: 3px solid #DED5F3;
        }

        .card-img{
            border: 3px solid #DED5F3;
        }

        .card-img__icon{
            fill: $primary;
        }
    }



    &.Beratung{
        .card-head {
            background-color: #FCCC9F;
        }
        &.highlighted{
            border: 3px solid #FA993F;
        }

        .card-img{
            border: 3px solid #FA993F;
        }

        .card-img__icon{
            fill: $wb-meganta;
        }
    }

    &.Bildung{
        .card-head {
            background-color: #FDEBB6;
        }
        &.highlighted{
            border: 3px solid #FCD770;
        }

        .card-img{
            border: 3px solid #FCD770;
        }

        .card-img__icon{
            fill: $wb-jade;
        }
    }

    &.Sprache{
        .card-head {
            background-color: #F6A7AB;
        }
        &.highlighted{
            border: 3px solid #ED4F58;
        }

        .card-img{
            border: 3px solid #ED4F58;
        }

        .card-img__icon{
            fill: $wb-jade;
        }
    }

    &.EhrenamtEngagement{
        .card-head {
            background-color: #A4E6C7;
        }
        &.highlighted{
            border: 3px solid #46CC8D;
        }

        .card-img{
            border: 3px solid #46CC8D;
        }

        .card-img__icon{
            fill: $wb-jade;
        }
    }

    &-img {

        width: 80px;
        height: 80px;
        border-radius: 100px;
        background-color: $white-color;
        border: 1px solid $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 17px;
        margin-top: 31px;

        img {
            max-width: 50px;
            max-height: 50px;
        }
    }

    &-content {
        padding: 20px;
        display: flex;
        align-items: center;

        .category {
            color: #3A3A3A;
            margin-bottom: 20px;
            font-size: 14px;
            text-transform: uppercase;
        }

        .time {
            color: $text-light;
            font-size: 14px;
            font-family: "Open Sans", sans-serif;
            margin-bottom: 0;
            font-weight: 600;

            @media (min-width: 992px) {
                font-size: 16px;
            }
        }

        .title {
            font-size: 16px;
            line-height: 24px;
            margin-top: 6px;
            margin-bottom: 12px;
            word-break: break-word;
        }

        .image {
            img {
                max-height: 80px;
            }
        }

        .desc {
            height: 68px;
            line-height: 20px;
            font-size: 14px;
            margin-bottom: 10px;
        }

        &__list {
            display: block;
            list-style: none;

            li {
                display: flex;
                align-items: flex-start;
                margin-bottom: 10px;
                color: $black-color;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;

                &:last-child {
                    margin-bottom: 0;
                }

                .icon {
                    span {
                        font-size: 18px;
                        margin-right: 15px;
                        line-height: 24px;
                    }
                }

                .info {
                    display: inline-flex;
                    flex-direction: column;
                }
            }
        }
    }

    &:first-child {
        margin-left: 0px;

        @media (max-width: 991px) {
            margin-left: 0px;
        }
    }

    &-block {
        min-width: 290px;
        max-width: 100%;
        width: 100%;
        margin-right: 0;

        &:first-child {
            margin-left: 0;
        }
    }

    &.no-slider {
        margin-right: 0;
        margin-left: 0px;
    }

}

.h5 {
    font-weight: 600;
}

.card-head {
    display: flex;
    height: 140px;
}

.icon-card {
    margin-top: 0;
}

.link-wrapper {
    width: 100%;
    margin-bottom: 20px;

    &:hover {
        text-decoration: none;
        box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 0px 40px;
    }
}

body[dir="rtl"] {
    .card{
        margin-right: 0;
        margin-left: 15px;

        .card-img {
            margin-left: 0;
            margin-right: 17px;
        }

        .card-content__list {
            li {
                .icon {
                    span {
                        margin-left: 15px;
                        margin-right: 0;
                    }
                }
            }
        }

        &:first-child {
            margin-right: 0px;
        }


        &.card-block {
            margin-left: 0;



        }
    }
}

</style>
